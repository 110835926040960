import apiService from './apiService.js'


export default {    
    async getAll() {
        return   apiService.get('Commentaires/');
    },
      
    async get(dicoId) {
        return   apiService.get('Commentaires/'+dicoId);
    },


    async save(clientId, etudeId, outil, item,value, commentaire,variant) {
        var com={Id:"",ClientId:clientId, EtudeId:etudeId, Outil:outil, Item:item, Nombre:value, Content:commentaire,Variant:variant};
        return   apiService.post('Commentaires/', com);
    },
    async delete(dicoId) {
        return   apiService.delete('Commentaires/'+dicoId);
    },
    
    async searchAllCom(  clientId, rdvId )
  {
    var request ={ ClientId:clientId,
        RdvId:rdvId,

    };
        return   apiService.post('Commentaires/Search/', request);
    },
    async search(  clientId, rdvId, outil, item,nombre,variant )
  {
   
    var request ={ ClientId:clientId,
        RdvId:rdvId,
         Outil:outil,
          Item:item,
        Nombre:nombre,
        Variant:variant
    };
        return   apiService.post('Commentaires/Search/', request);
    },

 
    async searchTuple(  clientId, rdvId, outil, tuples ,    variant)
    {   
      var request ={ ClientId:clientId,
          RdvId:rdvId,
           Outil:outil,
           Tuples:tuples,
           Variant:variant
       
      };
          return   apiService.post('Commentaires/Search/', request);
      },
    async searchWithDefault(  clientId, rdvId, outil, item,nombre,whitderfault,variant )
  {//mettre en upercase les mois ?
    var request ={ ClientId:clientId,
        RdvId:rdvId,
         Outil:outil,
          Item:item,
        Nombre:nombre, 
        WithDefault:!!whitderfault,
        Variant:variant
    };
        return   apiService.post('Commentaires/Search/', request);
    },
    

    }