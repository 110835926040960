
 <template>
               <v-container>
                <v-card variant="flat">
                    <v-card-title v-if="!hideTitle" class="themeTitle" >Plan de vie</v-card-title>
              <v-card-text class="justify-center">

       <div>
         <div class="float-container"   style="shape-outside: content-box;
      
        shape-margin: 30px;">
          <div class="float-header">
            <div :class="['planCellTitre','planCellMission']" >  
              Mission</div> </div>
          <div class="float-header">
            <div :class="['planCellTitre','planCellCycle']" >  
              Cycles</div> </div>
          <div class="float-header">
            <div :class="['planCellTitre','planCellAction']" >  
          Actions</div> </div>
          <div class="float-child">
        
            <br/>   
            <br/> 
            <br/>
            <br/> 
            <br/>   
            <br/> 
            <br/>
            <br/> 
             <div :class="[planVie['Mission']['Mission'].valeur.length>2?'planCellMaitre':'planCell', showCom?'linklookalike':'','planCellMission', planVie['Mission']['Mission'].couleur]"   v-on:click="showComModal('Mission',planVie['Mission']['Mission'].valeur)"     >  
                <div>{{  planVie['Mission']['Mission'].valeur}}</div></div>
          </div>
          
          <div class="float-child cursor-pointer">
      
            <div  v-on:click="showComModal('Cycle 1',planVie['Cycles']['1er cycle'].valeur)"    
             :class="[planVie['Cycles']['1er cycle'].valeur.length>2?'planCellMaitre':'planCell','planCellCycle', showCom?'linklookalike':'',planVie['Cycles']['1er cycle'].couleur]" style=" position: absolute; top:55px; left:120px;width: 100px;" >
              <br/>  <br/>   <br/> 
              <div   class="cursor-pointer">{{  planVie['Cycles']['1er cycle'].valeur}}</div>
            </div>

         


           <div :class="[planVie['Cycles']['2e cycle'].valeur.length>2?'planCellMaitre':'planCell','planCellCycle', planVie['Cycles']['2e cycle'].couleur]" style="border-top-style: solid;border-color: black;border-width: thin; position: absolute; top: 184px; left:120px; width: 100px;" >
                <div style="color:black;font-size:12px !important"> {{  planVie['Cycles']['2e cycle'].detail}}</div>
</div>
             <div v-on:click="showComModal('Cycle 2',planVie['Cycles']['2e cycle'].valeur)"   :class="[planVie['Cycles']['2e cycle'].valeur.length>2?'planCellMaitre':'planCell', showCom?'linklookalike':'','planCellCycle', planVie['Cycles']['2e cycle'].couleur]" style="position: absolute; top: 250px; left:120px;width: 100px; " >
               <div> {{  planVie['Cycles']['2e cycle'].valeur}}</div>
             </div>
            <div :class="[planVie['Cycles']['3e cycle'].valeur.length>2?'planCellMaitre':'planCell','planCellCycle', planVie['Cycles']['3e cycle'].couleur]" style=" border-top-style: solid;border-color: black;border-width: thin;   position: absolute; top: 328px; left:120px; width: 100px;">
             <div  style="color:black;font-size:12px !important">   {{  planVie['Cycles']['3e cycle'].detail}}</div>
    
            </div>
            <div v-on:click="showComModal('Cycle 3',planVie['Cycles']['3e cycle'].valeur)"   
            :class="[planVie['Cycles']['3e cycle'].valeur.length>2?'planCellMaitre':'planCell', showCom?'linklookalike':'','planCellCycle', planVie['Cycles']['3e cycle'].couleur]" style=" position: absolute; top:390px; left:120px; width:100px ">
              <div>  {{  planVie['Cycles']['3e cycle'].valeur}}</div>
            </div>
          </div>

          <div class="float-child">
 
            <div v-on:click="showComModal('Action 1',planVie['Actions']['1ère action'].valeur)"    :class="[planVie['Actions']['1ère action'].valeur.length>2?'planCellMaitre':'planCell',showCom?'linklookalike':'','planCellAction', planVie['Actions']['1ère action'].couleur]" :style="computeFirstActionStyle(36-  parseNumero(planVie['Mission']['Mission'].valeur))">
              <br/>   
              <br/> 
       
              <br/>  
              <div style='display: block;'>{{  planVie['Actions']['1ère action'].valeur}}</div>
            </div>
            <div  :class="[planVie['Actions']['1ère action'].valeur.length>2?'planCellMaitre':'planCell','planCellAction', planVie['Actions']['2ème action'].couleur]"  :style="computeStyleHeader(36-  parseNumero(planVie['Mission']['Mission'].valeur))">  
              <div  style="color:black; font-size:12px !important;display: block;">{{  planVie['Actions']['1ère action'].detail}}</div>
            </div>
            <div v-on:click="showComModal('Action 2',planVie['Actions']['2ème action'].valeur)"   :class="['planCellSmall',showCom?'linklookalike':'','planCellAction', planVie['Actions']['2ème action'].couleur]"  :style="computeStyle((36-  parseNumero(planVie['Mission']['Mission'].valeur)),(45-  parseNumero(planVie['Mission']['Mission'].valeur)) )">  
              <div>{{  planVie['Actions']['2ème action'].valeur}}</div>
              
            </div>
            <div :class="[planVie['Actions']['2ème action'].valeur.length>2?'planCellMaitre':'planCell','planCellAction', planVie['Actions']['2ème action'].couleur]"  :style="computeStyleHeader(45-  parseNumero(planVie['Mission']['Mission'].valeur))">  
              <div  style="color:black; font-size:12px!important">{{  planVie['Actions']['2ème action'].detail}}</div>
            </div>
            <div v-on:click="showComModal('Action 3',planVie['Actions']['3ème action'].valeur)"  :class="['planCellSmall',showCom?'linklookalike':'','planCellAction', planVie['Actions']['3ème action'].couleur]"  :style="computeStyle(45-  parseNumero(planVie['Mission']['Mission'].valeur),54-  parseNumero(planVie['Mission']['Mission'].valeur)) ">  
              <div>{{  planVie['Actions']['3ème action'].valeur}}</div>
            </div>
            <div :class="[planVie['Actions']['3ème action'].valeur.length>2?'planCellMaitre':'planCell','planCellAction', planVie['Actions']['2ème action'].couleur]"  :style="computeStyleHeader(54-  parseNumero(planVie['Mission']['Mission'].valeur))">  
              <div  style="color:black;font-size:12px !important">    {{  planVie['Actions']['3ème action'].detail}}</div>
            </div>
            <div v-on:click="showComModal('Action 4',planVie['Actions']['4ème action'].valeur)"   :class="[planVie['Actions']['4ème action'].valeur.length>2?'planCellMaitre':'planCell',showCom?'linklookalike':'','planCellAction', planVie['Actions']['4ème action'].couleur]" :style="computeStyle(54-  parseNumero(planVie['Mission']['Mission'].valeur),81) ">
         
              <br/>   
              <br/> 
              <br/>   
              <br/> 
              <div >{{  planVie['Actions']['4ème action'].valeur}} </div>
          </div>
        </div>
          
        </div>
        <p  v-for="item in filterItems" class="content ql-editor pa-1 mb-6"   v-html="item.content"  :key="item"> </p>
     </div>
           
                </v-card-text>
                </v-card>
              <CommentaireSaisieComponent   :variant="crtVariant" v-on:updated='this.$emit("commentaireUpdated")' :show="showCommentaireSaisieComponent" :etudeId="etudeId" :clientId="clientId" :outil="outil" :item="item"  :value="crtValue" v-on:closed="showCommentaireSaisieComponent=false"></CommentaireSaisieComponent>

                </v-container>
       
 </template>
 // propriete  destinée à recevoir le theme de l'experience

    <script>
      import CommentaireSaisieComponent from "../components/CommentaireSaisieComponent.vue";
      import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  props: ['planVie','showCom','etudeId','clientId','solo','commentaires',"variant","hideTitle"],
  components: {CommentaireSaisieComponent},
  computed:{
    filterItems() {    return this.commentaires?this.commentaires.filter(d=> d.outil==this.outil && d.variant == this.variant):[] },},

  data() {
    return {
      coms:[],
      showCommentaireSaisieComponent: false,
      outil:"Plan de vie",
      item:"1",
      crtValue:"1",
      valtmp:"",
      crtVariant: "",
    }
  }, 
  methods: {

   
    // fonction pour afficher le theme de l'experience
    parseNumero(numero)
    {
      if(numero=="11/2") return 2;
      if(numero=="22/4") return 4;
      if(numero=="33/6") return 6;
      if(numero=="44/8") return 8
      return numero;
    },
    computeFirstActionStyle(age) {
      //todo compute hight
 
      return "  position: absolute; top:55px;height: "+(((5.33)*age)-15)+"px; left:220px; width: 100px;";

    },
    showComModal(itx, value){
      if(this.showCom){
      this.item = itx;
      this.crtVariant = this.variant;
      this.crtValue = value;
      this.showCommentaireSaisieComponent = this.showCom;
    }  },
    computeStyleHeader(age) {
      //todo compute hight refeaire les calcul pour une hauteur de 552

      return "position: absolute;border-bottom-style: solid;border-color: black; border-width: thin; top: "+(((5.33)*age)+15)+"px; left:220px; width: 100px;";
    
  },
    computeStyle(age,ageNext) {
      //todo compute hight
 
      return " position: absolute; top: "+(((5.33)*age)+40)+"px; height: "+(((5.33)*(ageNext-age)))+"px;left:220px; width: 100px;";

    }
  }
}
    </script>