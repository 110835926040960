import apiService from './apiService.js'


export default {
  
  async getAll() {
  /*  const accessToken = await client.getAccessTokenSilently() 
    console.log(accessToken)*/
      return   apiService.get('clients/');
    },
    
  async get(adhId) {
      return   apiService.get('clients/'+adhId);
    },
    async getNotes(adhId) {
      return   apiService.get('clients/'+adhId+'/Notes/');
    },
    async addNotes(adhId,note) {
      return   apiService.post('clients/'+adhId+'/Notes/',note);
    },
    async editNotes(adhId,note){
      return   apiService.post('clients/'+adhId+'/Notes/',note);
    },
    async   deleteNotes(adhId,noteId) { 
      return   apiService.delete('clients/'+adhId+'/Notes/'+noteId);
    },
    async getChampExperience(client) {
      return   apiService.post('clients/ChampExperience/', client);
    },
    async getPlanExpression(client) {
      return   apiService.post('clients/PlanExpression/', client);
    },
    async getDoubleSpiral(client) {
      return   apiService.post('clients/DoubleSpiral/', client);
    },
    async getCycleNaissance(client) {
      return   apiService.post('clients/CycleNaissance/', client);
    },

    async getPlanVie(client) {
      return   apiService.post('clients/PlanVie/', client);
    },
    async getCycleNeuf(clientId,dateetude) {
      var request ={ DateEtude:dateetude};
      return   apiService.post('clients/'+clientId+'/CycleNeuf/', request);
    },
    async getPlanActionAnnee(clientId,dateetude) {
      var request ={ DateEtude:dateetude};
      return   apiService.post('clients/'+clientId+'/PlanActionAnnee/', request);
    },
    async getPlanCycleAnnee(clientId,dateetude) {
      var request ={ DateEtude:dateetude};
      return   apiService.post('clients/'+clientId+'/PlanCycleAnnee/', request);
    },
    async getCycleAnnee(clientId,dateetude) {
      var request ={ DateEtude:dateetude};
      return   apiService.post('clients/'+clientId+'/CycleAnnee/', request);
    },
  
    async getAgendaMois(clientId,dateetude) {
      var request ={ DateEtude:dateetude};
      return   apiService.post('clients/'+clientId+'/AgendaMois/', request);
    },
    async getAgendaMoisJour(clientId,dateetude) {
      var request ={ DateEtude:dateetude};
      return   apiService.post('clients/'+clientId+'/AgendaMoisJour/', request);
    },
    async getAnneePersonnelle(clientId,dateetude) {
      var request ={ DateEtude:dateetude};
      return   apiService.post('clients/'+clientId+'/AnneePersonnelle/', request);
    },
    async getMoisPersonnel(clientId,dateetude) {
      var request ={ DateEtude:dateetude};
      return   apiService.post('clients/'+clientId+'/MoisPersonnel/', request);
    },
    async getJourPersonnel(clientId,dateetude) {
      var request ={ DateEtude:dateetude};
      return   apiService.post('clients/'+clientId+'/JourPersonnel/', request);
    },
    async getMoisCycle(clientId,dateetude) {
      var request ={ DateEtude:dateetude};
      return   apiService.post('clients/'+clientId+'/MoisCycle/', request);
    },

    async  save(client) {
      return   apiService.post('clients/', client);
    },
   async exporter() {
    apiService.get('clients/Export', {responseType: 'blob'}) .then((response) =>{
  
    // 2. Create blob link to download
     const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `clients.xlsx`);
     // 3. Append to html page
     document.body.appendChild(link);
     // 4. Force download
     link.click();
     // 5. Clean up and remove the link
     link.parentNode.removeChild(link);
    })
   
       // window.open(FILE);
      },
     
  async delete(adhId) {
      return   apiService.delete('clients/'+adhId);
    },


    
 
}