
<template>
    <v-container class="pa-0"><p   class="content ql-editor" v-html="commentaire"> </p>
    </v-container>
    </template>

    <script setup>

</script>
    <script>
  
import { Quill } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import imageCompressor from 'quill-image-compress'
  import commentaireService from '../services/commentaireService';
  import configService from '../services/configService';

  Quill.register("modules/imageCompressor", imageCompressor);
      export default {
        props: ['show','etudeId','clientId','outil','item','value','timeStamp'],
        components: {
    
  },
        data () {
          return {
            exemple:"exemple",
            search: '',
            isshow:false,
            commentaire:' ',
            editorOption: configService.getQuillConfig(),
          }
        },
        methods: {
         
         
        },
        mounted() {
          this.$nextTick(() => {

            commentaireService.search(  this.clientId, this.etudeId, this.outil, this.item,this.value,this.value ).then((response) => {
              if(response.data && response.data.length>0)
             { 
              this.commentaire=response.data[0].content;
             }
            else
            this.commentaire=" ";
            });
          });
        },
        watch  : {
          timeStamp() {
          
            commentaireService.search(  this.clientId, this.etudeId, this.outil, this.item,this.value,this.value ).then((response) => {
              if(response.data && response.data.length>0)
             {
              this.commentaire=response.data[0].content;
             }
            else
            this.commentaire=" ";
            });},
          etudeId() {
      
            commentaireService.search(  this.clientId, this.etudeId, this.outil, this.item,this.value,this.value ).then((response) => {
              if(response.data && response.data.length>0)
             {
              this.commentaire=response.data[0].content;
             }
            else
            this.commentaire=" ";
            });

    },
   
  },
      }
    </script>