
<template>
    <v-container class="pa-0" v-if="commentaire">
       <div v-if="!!!withoutHeader" :class=" getCouleur(value)"> Filtre {{item}} - {{value}}</div>
       <div  id="preview" class="content ql-editor pa-1" v-html="commentaire" > </div>
      </v-container>
    </template>

    <script setup>

</script>
    <script>
  
import { Quill } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import imageCompressor from 'quill-image-compress'
  import commentaireService from '../services/commentaireService';
  import configService from '../services/configService';

  Quill.register("modules/imageCompressor", imageCompressor);
      export default {
        props: ['show','etudeId','clientId','outil','item','value','timeStamp', 'withoutHeader', 'withDefault'],
        components: {
    
  },
        data () {
          return {
            Couleurs:[ "noire","rouge","bleu","jaune","marron","vert_clair","rose","violet","vert_fonce","safran"],
            exemple:"exemple",
            search: '',
            isshow:false,
            commentaire:'',
            itemLabel:'',
            editorOption: configService.getQuillConfig(),
          }
        },
        methods: {
          getCouleur(chiffre)
          {
           
    if(chiffre=="11/2") return this.Couleurs[2]; 
    if(chiffre=="22/4") return this.Couleurs[4]; 
    if(chiffre=="33/6")  return this.Couleurs[6]; 
    if( chiffre=="44/8") return this.Couleurs[8];
    if(chiffre=="55/1") return this.Couleurs[1];
    if(chiffre=="66/3") return this.Couleurs[3];
    if( chiffre=="77/5") return this.Couleurs[5];
    if(chiffre=="88/7") return this.Couleurs[7];
    if(chiffre=="99/9") return this.Couleurs[9];
    if(chiffre=="10") return this.Couleurs[1]; 
    if(chiffre=="11") return this.Couleurs[2]; 
    if(chiffre=="22") return this.Couleurs[4]; 
    if(chiffre=="33")  return this.Couleurs[6]; 
    if(chiffre=="44") return this.Couleurs[8];
    if(chiffre=="55") return this.Couleurs[1];
    if(chiffre=="66") return this.Couleurs[3];
    if( chiffre=="77") return this.Couleurs[5];
    if(chiffre=="88") return this.Couleurs[7];
    if(chiffre=="99") return this.Couleurs[9];
    
    var val = parseInt(chiffre);
    return this.Couleurs[val];

          }
         
        },
        mounted() {   
          this.$nextTick(() => {
       
            commentaireService.searchWithDefault(  this.clientId, this.etudeId, this.outil, this.item,this.value, this.withDefault ).then((response) => {
              if(response.data && response.data.length>0)
             { 
       
              this.commentaire=response.data[0].content;
              this.  itemLabel=response.data[0].itemLabel;
             }
            else
            this.commentaire="";
            });
          });
        },
        watch  : {
          timeStamp() {
           
            commentaireService.searchWithDefault(  this.clientId, this.etudeId, this.outil, this.item,this.value, this.withDefault).then((response) => {
              if(response.data && response.data.length>0)
             {
          
              this.commentaire=response.data[0].content;
              this.  itemLabel=response.data[0].itemLabel;
             }
            else
            this.commentaire="";
            });},
          etudeId(val) {
            var tartouile= val
            if(tartouile!= val){
            this.commentaire="";
            commentaireService.searchWithDefault(  this.clientId, this.etudeId, this.outil, this.item,this.value, this.withDefault ).then((response) => {
              if(response.data && response.data.length>0)
             {
             
              this.commentaire=response.data[0].content;
              this.itemLabel=response.data[0].itemLabel;
             }
            else
            this.commentaire="";
            });
          }

    },
   
  },
      }
    </script>