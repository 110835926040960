
      <template>
      <v-container>
Demo !
<v-text-field
label="taille 10"
v-model="exemple"
hide-details

></v-text-field>
<v-text-field
class="v-field12"
label="taille 12"
hide-details
v-model="exemple"
></v-text-field>
<v-text-field
label="taille 15"
class="v-field15"
hide-details
v-model="exemple"
></v-text-field>
<v-text-field
label="taille 18"
class="v-field18"
hide-details
v-model="exemple"
></v-text-field>
<v-text-field
v-model="exemple"
label="taille 20"
class="v-field20"
hide-details

></v-text-field>
      </v-container>
      </template>

      <script setup>

</script>
      <script>
    
        export default {
          data () {
            return {
              exemple:"exemple",
              search: '',
      
            }
          },
          methods: {
          
          },
        }
      </script>