import apiService from './apiService.js'


export default {
   getLogoPath(adherent) {return apiService.defaults.baseURL+'media/'+adherent.id},
  async getAll() {
  /*  const accessToken = await client.getAccessTokenSilently() 
    console.log(accessToken)*/
      return   apiService.get('adherents/');
    },
    
    async  bloquer(adherent) {

      return   apiService.post('adherents/bloquer', adherent);
    },
    async  askChangePassword(adherent, newOne) {
      var request ={IdAdherent:adherent.id, NewPassword:newOne};
      return   apiService.post('adherents/ChangePassword', request);
    },
    async  debloquer(adherent) {

      return   apiService.post('adherents/debloquer', adherent);
    },
    async  renouveller(adherent, renouvellement) {
      adherent.renouvellement=renouvellement;
      return   apiService.post('adherents/renouveller', adherent);
    },
    async acceptCgu(){
      return   apiService.post('AcceptCgu');
    },
  async get(adhId) {
      return   apiService.get('adherents/'+adhId);
    },
    async  saveLogo(adherent,logo) {
      const formData = new FormData();
      formData.append("file", logo, logo.name);
      return   apiService.post('media/'+adherent.id, formData,{
        headers: {
        "Content-Type": "multipart/form-data",
        },
        });
    },

    async  askDelete(adherent) {
      return   apiService.delete('me/'+adherent.id);
    },
    async  save(adherent) {
      return   apiService.post('adherents/', adherent);
    },
    async  saveMe(adherent) {
      return   apiService.post('me/', adherent);
    },
  async register(adherent) {
      return   apiService.post('adherents/register/', {"data":adherent._value.family_name} );
    },
     
  async delete(adhId) {
      return   apiService.delete('adherents/'+adhId);
    },

    async exporter() {

      apiService.get('adherents/Export', {responseType: 'blob'}) .then((response) =>{
  
        // 2. Create blob link to download
         const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `adherents.xlsx`);
         // 3. Append to html page
         document.body.appendChild(link);
         // 4. Force download
         link.click();
         // 5. Clean up and remove the link
         link.parentNode.removeChild(link);
        })
       
           // window.open(FILE);
          },
   
    async me() {
      return   apiService.get('me' );
    },
 
}