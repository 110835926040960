
      <template>
      <v-container>
        <v-data-table :search="search"   :items-per-page-options='footerOptions'   :items-per-page="25"   no-data-text="Aucune paramêtre"   height="75dvh"  :items="aide"  hide-default-header="true"  :headers="headers">
          <template v-slot:top>
            <v-row>
              <v-col cols="10">
                <v-text-field v-model="search" label="Rechercher " class="mx-4"></v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-tooltip text="Ajouter une aide">
                  <template v-slot:activator="{ props }">   
                    <v-btn v-bind="props" icon="mdi:mdi-plus" size="large"></v-btn>
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          

          <template v-slot:item="{ item }" >
            <tr
          
            >
              <td>{{ item.raw.id }}</td>
              <td>{{ item.raw.name }}</td>
              <td>    
                <v-icon color="red"   @click="editItem(item)">
                mdi:mdi-pencil
              </v-icon>
              <v-icon color="red"   @click="deleteItem(item)">
                mdi:mdi-delete
              </v-icon></td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
      </template>
      <script setup>
  import { VDataTable } from 'vuetify/labs/VDataTable'
</script>
      <script>
    
        export default {
          data () {
            return {
              search: '',
              headers: [
                {title: 'Id', key: 'id'},
                {title: 'Name', key: 'name'},
                {title: 'Action'},
              ],
              footerOptions:[
  {value: 10, title: '10'},
  {value: 25, title: '25'},
  {value: 50, title: '50'},
  {value: 100, title: '100'},
  {value: -1, title: 'Tous'}
],
            aide: [ 
                {
                  name: 'Logiciel',
                  id: 159,
                },
                {
                  name: 'Personnalisation',
                  id: 237,
                },
                {
                  name: 'Couverture',
                  id: 262,
                },
                {
                  name: 'Liste des clients',
                  id: 305,
                },
                {
                  name: 'Ajouter un client',
                  id: 356,
                },
                {
                  name: 'Dossier client',
                  id: 375,
                },
                {
                  name: 'Bloc Note',
                  id: 392,
                },
                {
                  name: 'Thème de naissance',
                  id: 408,
                },
                {
                  name: 'Impression du dossier',
                  id: 452,
                },
                {
                  name: 'Dictionnaire',
                  id: 518,
                },
              ],
            }
          },
          methods: {
            addone() {
              alert("not implemented")
            },
            deleteItem() {
              alert("not implemented")
            },
            editItem() {
              alert("not implemented")
            },
          },
        }
      </script>