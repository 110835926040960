export default {

getQuillConfig(){

    return{
        modules: { 
      
           toolbar: {  container:[
                            ['bold', 'italic', 'underline'],
                            ['blockquote', 'code-block'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            [{ 'indent': '-1' }, { 'indent': '+1' }],
                            [{ 'direction': 'rtl' }],
                            [{ 'size': [false, '10px', '12px', '14px', '16px', '18px', '20px', '24px','30px','32px','36px','48px','60px','72px','96px'] }],
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
                            [{ 'color': [
                                         "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                          "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                          "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                          "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                          "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                          "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }, 
                                { 'background':[
                                         "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                          "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                          "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                          "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                          "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                          "#F80202","#08709F", "#fcf011", "#754619", "#6ABB0D", "#E33292", "#684088", "#005731", "#FA851C"] }],
                            [{ 'align': [] }],
                            ['clean'],
                          
                      
                        
                          
                            
                          ],},
            imageCompressor: {
              quality: 0.9,
              maxWidth: 1000, // default
              maxHeight: 1000, // default
              imageType: 'image/jpeg'
            }
          }};
},

}