 <template>
  <v-dialog class="rounded-t-xl"
  v-model="dialog"
  fullscreen
  :scrim="false"
  transition="dialog-bottom-transition"
>

  <v-card >
    <v-toolbar
      dark
      class="rounded-t-xl"
      color="#05445E"
    >
    <template v-slot:image>
      <v-img
                  src="./fond_vague_long.png"
                  class="align-end"
                  gradient="to right, #05445EDD, #05445EFF"
                  cover
                ></v-img></template>
    
      <v-toolbar-title style="color:white">Conditions générales d'utilisations</v-toolbar-title>
      <v-spacer></v-spacer>
     
        <v-btn
        
          @click="accept"
          color="white"
        >
          Accepter
        </v-btn>
        <v-btn
        
        @click="     this.$emit('RefuseCGU');"
        color="white"
      >
        Refuser
      </v-btn>
    
    </v-toolbar>
    <v-card-text>
      <div v-html='message'></div>
   </v-card-text>
  </v-card>
</v-dialog>

</template>

 <script>
import messageService from '../services/messageService'
import adherentService from '../services/adherentService'
export default {
  name: 'CGUComponent',
  expose: ['open'],
  data() {
    return {

     dialog:false,
      message: 'Hello Vue!'
    }
  },
  methods: {
    accept(){
      adherentService.acceptCgu().then((x) => {
        console.log(x);
        this.dialog = false;
      });
    },
    open() {
      this.dialog = true;
      messageService.getCGU().then((response) => {
        this.message = response.data;
      });
    }
  }

}
</script>