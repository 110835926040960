<template>
    <div>
<table width="100%"  style="background-color: white;
margin: auto;
border-style: solid;
-webkit-border-horizontal-spacing: 0px;
-webkit-border-vertical-spacing: 0px;
border-width: 1px;">
 <tbody>
        <tr height="150px" >
   <td width="11%"  style=" border-style: solid; border-width: 1px; -webkit-border-horizontal-spacing: 0px;-webkit-border-vertical-spacing: 0px;vertical-align:top" >

     <span id="inplace_calendrier_general_1" :class="[showCom?'linklookalike':'']"  style="">   
      <CommentaireComponent  :withoutHeader="true" :withDefault="true" :timeStamp="timeStamp" :etudeId="etudeId"  
      outil="Jour énergie"  item="Jour"  value="1"  :clientId="clientId"></CommentaireComponent>  
     </span>

   </td>
   <td width="11%" style=" border-style: solid; border-width: 1px; -webkit-border-horizontal-spacing: 0px;-webkit-border-vertical-spacing: 0px;vertical-align:top"  >
       <span id="inplace_calendrier_general_2" :class="[showCom?'linklookalike':'']"  style="">
       <CommentaireComponent  :withoutHeader="true" :withDefault="true" :timeStamp="timeStamp" :etudeId="etudeId"  
       outil="Jour énergie"  item="Jour"  value="2"  :clientId="clientId"></CommentaireComponent>    </span>
       
   </td>
   <td width="11%" style=" border-style: solid; border-width: 1px; -webkit-border-horizontal-spacing: 0px;-webkit-border-vertical-spacing: 0px;vertical-align:top" >

    
     <span id="inplace_calendrier_general_3" :class="[showCom?'linklookalike':'']"  style=""  >
       <CommentaireComponent  :withoutHeader="true" :withDefault="true" :timeStamp="timeStamp" :etudeId="etudeId"  
       outil="Jour énergie"  item="Jour"  value="3"  :clientId="clientId"></CommentaireComponent>  </span>
 
   </td>
   <td width="11%" style=" border-style: solid; border-width: 1px; -webkit-border-horizontal-spacing: 0px;-webkit-border-vertical-spacing: 0px;vertical-align:top" >
     <span id="inplace_calendrier_general_4" :class="[showCom?'linklookalike':'']"  style=""> 
       <CommentaireComponent  :withoutHeader="true" :withDefault="true" :timeStamp="timeStamp" :etudeId="etudeId"  
       outil="Jour énergie"  item="Jour"  value="4"  :clientId="clientId"></CommentaireComponent>  </span>
   </td>
   <td width="11%"  style=" border-style: solid; border-width: 1px; -webkit-border-horizontal-spacing: 0px;-webkit-border-vertical-spacing: 0px;vertical-align:top"  >
    
   </td>
  </tr>
        <tr  height="150px"  >  
   <td width="11%"  style=" border-style: solid; border-width: 1px; -webkit-border-horizontal-spacing: 0px;-webkit-border-vertical-spacing: 0px;vertical-align:top" >
      <span id="inplace_calendrier_general_5" :class="[showCom?'linklookalike':'']" style=""  v>
       <CommentaireComponent  :withoutHeader="true" :withDefault="true" :timeStamp="timeStamp" :etudeId="etudeId"  
       outil="Jour énergie"  item="Jour"  value="5"  :clientId="clientId"></CommentaireComponent> 
    </span>
   </td>
   <td width="11%" style=" border-style: solid; border-width: 1px; -webkit-border-horizontal-spacing: 0px;-webkit-border-vertical-spacing: 0px;vertical-align:top" >
       <span id="inplace_calendrier_general_6" :class="[showCom?'linklookalike':'']"  style="">
       <CommentaireComponent  :withoutHeader="true" :withDefault="true" :timeStamp="timeStamp" :etudeId="etudeId"  
       outil="Jour énergie"  item="Jour"  value="6"  :clientId="clientId"></CommentaireComponent> 
    </span>
   </td>
   <td width="11%" style=" border-style: solid; border-width: 1px; -webkit-border-horizontal-spacing: 0px;-webkit-border-vertical-spacing: 0px;vertical-align:top" >
  
     <span id="inplace_calendrier_general_7" :class="[showCom?'linklookalike':'']"  style="">
       <CommentaireComponent  :withoutHeader="true" :withDefault="true" :timeStamp="timeStamp" :etudeId="etudeId"  
       outil="Jour énergie"  item="Jour"  value="7"  :clientId="clientId"></CommentaireComponent> 
    </span>
   </td>
   <td width="11%" style=" border-style: solid; border-width: 1px; -webkit-border-horizontal-spacing: 0px;-webkit-border-vertical-spacing: 0px;vertical-align:top" >
      <span id="inplace_calendrier_general_8" :class="[showCom?'linklookalike':'']"  style="">
       <CommentaireComponent  :withoutHeader="true" :withDefault="true" :timeStamp="timeStamp" :etudeId="etudeId"  
       outil="Jour énergie"  item="Jour"  value="8"  :clientId="clientId"></CommentaireComponent> 
    </span>
   </td>
   <td width="11%" style=" border-style: solid; border-width: 1px; -webkit-border-horizontal-spacing: 0px;-webkit-border-vertical-spacing: 0px;vertical-align:top"  >

     <span id="inplace_calendrier_general_9" :class="[showCom?'linklookalike':'']"  style="">
       <CommentaireComponent  :withoutHeader="true" :withDefault="true" :timeStamp="timeStamp" :etudeId="etudeId"  
       outil="Jour énergie"  item="Jour"  value="9"  :clientId="clientId"></CommentaireComponent> 
    </span>
   </td>
  </tr>
</tbody></table>

<CommentaireSaisieComponent v-on:updated='this.$emit("commentaireUpdated")' :show="showCommentaireSaisieComponent" :etudeId="etudeId" :clientId="clientId" :outil="outil" :item="item"  :value="crtValue" v-on:closed="showCommentaireSaisieComponent=false"></CommentaireSaisieComponent>

</div>
</template> 
<script setup>

</script>
    <script>
      import CommentaireSaisieComponent from "../components/CommentaireSaisieComponent.vue";
   import CommentaireComponent from "../components/CommentaireComponent.vue";
    export default {
        props: ['showCom','etudeId','clientId'],
        data() {
    return {
      showCommentaireSaisieComponent: false,
      outil:"Jour énergie",
      item:"1",
      crtValue:"1",

    }
  },
        components: {CommentaireSaisieComponent,CommentaireComponent},
        methods: {
 
 showComModal(itx, value){
   if(this.showCom){
   this.item = itx;
   this.crtValue = value;
   this.showCommentaireSaisieComponent = this.showCom;
 }  },

    formatedDate(date) {
        return new Date(date).toLocaleDateString('fr-FR');
    },
    calculateAge(birthday,dateEtude) { // birthday is a date
    var ageDifMs = (new Date(dateEtude)) - (new Date(birthday).getTime());
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}
}
}
</script>