<template>
    <v-row justify="center">
      <v-dialog class="rounded-t-xl"
        v-model="dialog"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition"
      >
     
        <v-card >
          <v-toolbar
            dark
            class="rounded-t-xl"
            color="#05445E"
          >
          <template v-slot:image>
            <v-img
                        src="./fond_vague_long.png"
                        class="align-end"
                        gradient="to right, #05445EDD, #05445EFF"
                        cover
                      ></v-img></template>
            <v-btn
              icon
              color="white"
              @click="close"
            >
              <v-icon>mdi:mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title style="color:white">Message</v-toolbar-title>
            <v-spacer></v-spacer>
           
              <v-btn
             :disabled="!message.text"
                v-if="creating"
                @click="save"
                color="white"
              >
                Enregistrer
              </v-btn>

          
          </v-toolbar>
          <v-form v-model="valid">
    <v-container>
        <v-row>  <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="message.title"
            :readonly="!creating"
         
            label="Titre"
            required
            hide-details
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="message.formatedFrom"
            readonly
          
            label="De"
            hide-details
            required
          ></v-text-field>
     
        </v-col>

<v-col
  cols="12"
  md="4"
>
        <v-text-field
            v-model="message.formatedWhen"
        
            readonly
            label="Date"
            hide-details
            required
          ></v-text-field>

        </v-col>

<v-col
  cols="12"

>    
        <v-textarea
            v-model="message.text"
            label="Message"
            :rules="messageRules"
            hide-details
            :readonly="!creating"
            required
          ></v-textarea>
        </v-col>    
      </v-row>
    
    </v-container>
  </v-form>
        </v-card>
      </v-dialog>

    </v-row>
  </template>
  

  
  <script>
    import messageService from '../services/messageService'
    export default {
      data () {
        return {
          creating:false,
          dialog:false,
          message:{ },
          messageRules: [
  value => {
    if (value) return true

    return 'le message est obligatoire'
  },
  
],
        }
      },
      expose: ['open','create'],
      emits: ['updatedMessages'],
      methods: {
 

            save() {
              if(this.valid)
              {
             
                messageService.add( this.message)
                .then(() => {
                  this.dialog=false;
                  this.$emit('updatedMessages');
                 })
                .catch((e) => {
                  console.log("serveur KO");
                  console.log(e);
                });
              }
            },
          
            close() {
                  this.$emit('updatedMessages');   
                  this.dialog=false
            },
          
            create(who, why) {
              this.dialog=true;
              this.creating=true;
              this.message ={title:why,
                id:'new',
              from:who.id,
              formatedFrom:who.nom +' '+who.prenom,
              when:new Date(), 
              formatedWhen:new Date().toLocaleDateString("fr") 
          }
            },
            open(messageId) {
              this.creating=false;
              messageService.get(messageId)
              .then((resutlt) => {
       
                this.message = resutlt.data
                messageService.read(messageId)
              
              })
              .catch((e) => {
                console.log("serveur KO");
                console.log(e);
              });
              this.dialog=true;
            },}
    }
  </script>
  
  <style>
  .dialog-bottom-transition-enter-active,
  .dialog-bottom-transition-leave-active {
    transition: transform .2s ease-in-out;
  }
  </style>