import apiService from './apiService.js'


export default {    
    async getAll(idClient) {
        return   apiService.get('clients/'+idClient+'/Rdvs');
    },
      
   
    async save(idClient,etude) {
        return   apiService.post('clients/'+idClient+'/Rdvs', etude);
    },

    async delete(idClient,idRdv) {
        return   apiService.delete('clients/'+idClient+'/Rdvs/'+idRdv);
    },

    }