
      <template>
      <v-container class="text-caption">
        <v-data-table    :items-per-page-options='footerOptions'    density="dense"   height="75dvh" :items-per-page="25"
         items-per-page-text="Message par page" :search="search" no-data-text="Aucun message"   :items="messages"  hide-default-header="true"  :headers="headers">
          <template v-slot:top>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="search" label="Rechercher "></v-text-field>
             </v-col>
            </v-row>
          </template>
          

          <template v-slot:item="{ item }" >
            <tr>
      
              <td class="text-caption">{{ item.raw.title }}</td>
              <td class="text-caption">{{ item.raw.formatedFrom }}</td>
              <td class="text-caption">  <v-icon   v-if="item.raw.read" >
                mdi:mdi-check
              </v-icon></td>
              <td class="text-caption">{{ item.raw.formatedWhen }}</td>
              <td class="text-caption">    
                <v-icon color="red"   @click="editItem(item)">
                mdi:mdi-eye
              </v-icon>
              <v-icon color="red"  v-if="item.raw.role!='Administrateur'" @click="deleteItem(item)">
                mdi:mdi-delete
              </v-icon>
            
            </td>
            </tr>
          </template>
        </v-data-table>
        <MessageDetailComponent ref='detailMessage' v-on:updatedMessages="refreshlist"/>
        <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Etes vous sure de vouloir supprimer <br/> ce message ? </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteConfirm">Valider</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-container>
      </template>
      <script setup>
  import { VDataTable } from 'vuetify/labs/VDataTable'

</script>
      <script>
     import {
  onMounted,
  ref,
} from 'vue';

    import { store } from '../store.js'
    import messageService from '../services/messageService'
    import MessageDetailComponent from '../components/MessageDetailComponent.vue'
    const detailMessage = ref({});

        export default {
          setup() {
            
    
   
    onMounted(() => {
      
      
  
    });

    return {
      detailMessage,
    };
  },
          components: {
             MessageDetailComponent,
  },
          mounted() {
            messageService.getMyMessages().then((resutlt) => {
              this.messages = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    });

   
          },
          data () {
            return {
              footerOptions:[
  {value: 10, title: '10'},
  {value: 25, title: '25'},
  {value: 50, title: '50'},
  {value: 100, title: '100'},
  {value: -1, title: 'Tous'}
],
              search: '',
              toDelete:null,
              dialogDelete:false,
              headers: [
                {title: 'Titre', key: 'title'},
                {title: 'From', key: 'from'},
                {title: 'Vu', },
                {title: 'Date', key: 'when'},
                {title: 'Action'},
              ],
            messages: [],
            }
          },
          methods: {
            closeDelete(){              
              this.dialogDelete = false
              this.toDelete=null
          },
            deleteConfirm(){
              this.dialogDelete = false
              messageService.delete( this.toDelete.id)
              .then( ()=>  messageService.getMyMessages()
                          .then((resutlt) => { this.messages = resutlt.data }))
              .catch((e) => {
                console.log("serveur KO");
                console.log(e);     
              });
            },
            refreshlist() {   
              messageService.getMyMessages()
              .then((resutlt) => { this.messages = resutlt.data })
              .catch((e) => {
                console.log("serveur KO");
                console.log(e);     
              });
              messageService.getUnreadMessages() .then((result) => {
        store.storeMessages(result.data)
       }).catch((e) => {
           
           console.log(e);
          
       });
      },
            
              addone() {
              this.$refs.detailMessage.create();
            },
            deleteItem(item) {
              this.toDelete = item.raw
              this.dialogDelete = true
 
            },
            editItem(item) {
              this.$refs.detailMessage.open(item.raw.id);            
            },
          },
        }
      </script>