<template>
  <v-row justify="center" class="text-caption">
    <v-dialog
      class="rounded-t-xl"
      v-model="dialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark class="rounded-t-xl" color="#05445E">
          <template v-slot:image>
            <v-img
                        src="./fond_vague_long.png"
                        class="align-end"
                        gradient="to right, #05445EDD, #05445EFF"
                        cover
                      ></v-img></template>
          <v-btn icon color="white" @click="dialog = false">
            <v-icon>mdi:mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title style="color:white">Adhérent {{adherent.nom}} {{adherent.prenom}}</v-toolbar-title>
        
        </v-toolbar>
        <v-form v-model="valid">
          <v-container>
            <v-tabs v-model="tab" bg-color="transparent" color="basil" grow>
              <v-tab key="Adherent" value="Adherent"> Adhérent </v-tab>
              <v-tab key="Personnalisation" value="Personnalisation">
                Personnalisation
              </v-tab>
              <v-tab key="Technique" value="Technique"> Technique </v-tab>
            </v-tabs>

            <v-window v-model="tab">
              <v-window-item key="Adherent" value="Adherent">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="adherent.prenom"
                        label="Prénom"
                        required
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="adherent.nom"
                        label="Nom"
                        hide-details
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="pa-0">
                  
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="adherent.telephone"
                        prepend-icon="mdi:mdi-phone"
                        label="Téléphone"
                        hide-details
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="pa-0">
                  
                    <v-col class="pa-1" cols="12" md="9">
                      <v-text-field
                        v-model="adherent.mail"
                        :rules="emailRules"
                        label="E-mail"
                        hide-details
                        required
                        prepend-icon="mdi:mdi-email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="pa-1">
                      <v-checkbox
                        readonly
                        hide-details
                        v-model="adherent.authorizeMail"
                        label="Accepte les emails d’information"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pa-1">
                      <v-textarea
                        v-model="adherent.adresse"
                        label="Adresse"

                        prepend-icon="mdi:mdi-home"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-window-item>
              <v-window-item key="Personnalisation" value="Personnalisation">
                <v-container>
                  <v-row class="pa-0">
                    <v-col cols="8" class="pa-1">
                      <v-row>
                        <v-col cols="8" class="pa-1">
                          <v-text-field
                            v-model="adherent.societe"
                            label="Société"
                            prepend-icon="mdi:X"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col cols="4" class="pa-1">
                          <v-btn 
                          :loading="isSelecting" 
                          @click="handleFileImport"
>
                          Choisissez un logo
                      </v-btn>
              
                      <!-- Create a File Input that will be hidden but triggered with JavaScript -->
                      <input 
                          ref="uploader" 
                          class="d-none" 
                          type="file" 
                          @change="onFileChanged"
                      ></v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-1" cols="12">
                          <v-text-field
                            v-model="adherent.intituleEtude"
                            prepend-icon="mdi:mdi-format-pilcrow"
                            label="Entete Etude"
                            hide-details
                          ></v-text-field>
                        </v-col>
                     
                      </v-row>
                      <v-row>
                        <v-col class="pa-1" cols="8">
                          <v-text-field
                            v-model="adherent.PiedIntituleEtude"
                            prepend-icon="mdi:mdi-format-pilcrow"
                            label="Pied page de garde"
                            hide-details
                          ></v-text-field>
                        </v-col>
                       <v-col class="pa-1" cols="4">
                          <v-select
                            v-model="adherent.tailleLogo"
                            :items="tailleLogos"
                            label="Taille Logo"
                            hide-details
                          ></v-select>
                        
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-1" cols="8">
                          <v-text-field
                            v-model="adherent.pied1"
                            prepend-icon="mdi:mdi-format-pilcrow"
                            label="Pied1"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pa-1">
                          <v-select
                            label="Alignement"
                            v-model="adherent.pied1Align"
                            hide-details
                            :items="['Centrer', 'Gauche', 'Droite', '']"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0">
                        <v-col cols="8" class="pa-1">
                          <v-text-field
                            v-model="adherent.pied2"
                            hide-details
                            prepend-icon="mdi:mdi-format-pilcrow"
                            label="Pied2"
                          ></v-text-field>
                        </v-col>
                        <v-col class="pa-1" cols="4">
                          <v-select
                            label="Alignement"
                            v-model="adherent.pied2Align"
                            hide-details
                            :items="['Centrer', 'Gauche', 'Droite', '']"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0">
                        <v-col cols="8" class="pa-1">
                          <v-text-field
                            v-model="adherent.pied3"
                            prepend-icon="mdi:mdi-format-pilcrow"
                            label="Pied3"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pa-1">
                          <v-select
                            label="Alignement"
                            hide-details
                            v-model="adherent.pied3Align"
                            :items="['Centrer', 'Gauche', 'Droite', '']"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="pa-1" cols="4">
                      
                          <v-img
                          v-if="logoPath"
                          :width="200"
                          cover
                          :src="logoPath"
                        ></v-img>          <v-img
                        v-else
                        :width="200"
                        cover
                        src="nologo.png"
                      ></v-img>
                    ></v-col>
                  </v-row>
                </v-container>
              </v-window-item>
              <v-window-item key="Technique" value="Technique">
                <v-container class="bg-red-lighten-2">
                  <v-row>
                    <v-col
                    cols="3"
                    class="pa-1"
                    v-if="adherent.role != 'Administrateur'"
                  >
                    <v-btn @click="renouveller"  elevation="4" color="red-darken-3"> Renouveller </v-btn></v-col
                  >    
                    <v-col
                      cols="3"
                      class="pa-1"
                      v-if="adherent.role != 'Administrateur'"
                    >
                      <v-btn  elevation="4" color="red-darken-3" v-if="!adherent.bloque" @click="bloquer">
                        Bloquer
                      </v-btn>
                      <v-btn  elevation="4" color="red-darken-3" v-else @click="debloquer">
                        Debloquer
                      </v-btn>
                    </v-col>
                    
        
                    <v-col
                      cols="3"
                      class="pa-1"
                      v-if="adherent.role != 'Administrateur'"
                    >
                      <v-btn   elevation="4" color="red-darken-3" @click="promouvoir"> Administrateur </v-btn></v-col
                    >

                    <v-col
                      cols="3"
                      class="pa-1"
                      v-if="adherent.role == 'Administrateur' && adherent.id != moi.id"
                    >
                      <v-btn @click="retrograder"  elevation="4" color="red-darken-3">
                        Retrograder
                      </v-btn></v-col
                    >
                    <v-col
                    v-if="adherent.role != 'Administrateur'"
                      class="pa-1"
                      cols="3"
                    >
              
                      <v-btn  prepend-icon="mdi:mdi-delete-forever"  elevation="4" color="red-darken-3"  @click="supprimer"> Supprimer </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="adherent.role != 'Administrateur'"
                      class="pa-1"
                      cols="3"
                    >
                      <v-select
                        v-if="adherent.role != 'Administrateur'"
                        label="Role"
                        hide-details
                        v-model="adherent.role"
                        :items="[
                          'Adherent',
                          'Adherent Avance',
                          'Adherent temporaire',
                          '',
                        ]"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="3"
                      class="pa-1"
                      v-if="adherent.role != 'Administrateur'"
                    >
                      <v-text-field
                      v-model="adherent.finLicenceForInput"
                      label="Fin licence"
                      type="date"
                      hide-details
                 
                    ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="pa-1">
                      <v-checkbox
                        readonly
                        v-model="adherent.bloque"
                        label="Bloqué"
                        disabled
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" class="pa-1">
                      <v-checkbox
                        v-model="adherent.delAsked"
                        hide-details
                        label="Demande suppression"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-window-item>
            </v-window>
                        
<v-card-actions>
  <v-btn prepend-icon="mdi:mdi-content-save"    color="#05445E"  variant="flat"  class="text-white " 
 @click="save"
 >
 Enregistrer
 </v-btn>
 </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" width="400">
      <v-card  width="400">
        <v-card-title class="text-h5"
          >Suppression
        </v-card-title>
        <v-card-text>Etes vous sure de vouloir supprimer cet adhérent ?</v-card-text> 
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteConfirm"
            >Valider</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="renouv" persistent width="1024">
      <v-card width="400">
        <v-card-item>
          <v-card-title>Renouvellement</v-card-title>
        </v-card-item>

        <v-card-text>
          Pour quelle periode souhaitez vous renouveller la licence ?
          <v-select
            label="Periode"
            v-model="renouvellement"
            :items="['1 mois', '6 mois', '1 an']"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeRenouveller"> Fermer </v-btn>
          <v-btn text @click="renouvellerConf"> Renouveller </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <v-dialog v-model="retragraderAdm" persistent width="400">
      <v-card width="400">
        <v-card-item>
          <v-card-title>Administrateur </v-card-title>
        </v-card-item>

        <v-card-text>
          Etes vous sure de vouloir retirer à cet utilisateur ses droits
          administrateurs ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeRetrograder"> Fermer </v-btn>
          <v-btn text @click="validerRetrograder"> Valider </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <v-dialog v-model="promouvoirAdm" persistent width="400">
      <v-card width="400">
        <v-card-item>
          <v-card-title>Administrateur</v-card-title>
        </v-card-item>

        <v-card-text>
          Etes vous sure de vouloir donner à cet utilisateur des droits
          administrateurs ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closePromouvoir"> Fermer </v-btn>
          <v-btn text @click="validerPromouvoir"> Valider </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </v-row>
</template>

<script>
import adherentService from "../services/adherentService";
import { store } from '../store.js'
export default {
  mounted() {
              this.moi = store.user
        
          },
  data() {
    return {
      isSelecting: false,
      selectedFile: null,
      moi:null,
      logo: null,
      logoPath: "",
      dialog: false,
      tab: "Adherent",
      dialogDelete: false,
      renouv: false,
      menu2: false,
      retragraderAdm: false,
      promouvoirAdm: false,
      renouvellement: "",
      valid: false,
      tailleLogos:["S","M","L","XL"],
      adherent: { prenom: "", id: "", role: "", nom: "", mail: "" },
      nameRules: [
        (value) => {
          if (value) return true;

          return "Name is required.";
        },
        (value) => {
          if (value?.length <= 10) return true;

          return "Name must be less than 10 characters.";
        },
      ],

      emailRules: [
        (value) => {
          if (value) return true;

          return "E-mail is requred.";
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;

          return "E-mail must be valid.";
        },
      ],
    };
  },
  expose: ["open", "create"],
  emits: ["updatedAdherents"],
  methods: {
    handleFileImport() {
                this.isSelecting = true;

                // After obtaining the focus when closing the FilePicker, return the button state to normal
                window.addEventListener('focus', () => {
                    this.isSelecting = false
                }, { once: true });
                
                // Trigger click on the FileInput
                this.$refs.uploader.click();
            },
            onFileChanged(e) {
                 this.logo = e.target.files;
                if(this.logo && this.logo.length>0  && this.logo[0])     this. logoPath=   URL.createObjectURL(this.logo[0])

                // Do whatever you need with the file, liek reading it with FileReader
            },
    changeLogo() {
      if (this.logo && this.logo.length > 0 && this.logo[0])
        this.logoPath = URL.createObjectURL(this.logo[0]);
    },
    closeRetrograder() {
      this.retragraderAdm=false;
    },
    promouvoir() {
      this.     promouvoirAdm= true
    },
    retrograder() {
   
      this.retragraderAdm=true;
 
    },
    closePromouvoir() {      this.promouvoirAdm= false},
    validerPromouvoir() {      this.promouvoirAdm= false
    this.adherent.role='Administrateur';
    },
    validerRetrograder() {    
        this.adherent.role='Adherent';
       this.retragraderAdm=false;},
    supprimer() {
      this.dialogDelete = true;
    },
    save() {
      if (this.valid) {
        if (this.logo && this.logo.length > 0 && this.logo[0])
          adherentService.saveLogo(this.adherent, this.logo[0]);
        adherentService
          .save(this.adherent)
          .then(() => {
            this.dialog = false;
            this.$emit("updatedAdherents");
          })
          .catch((e) => {
            console.log("serveur KO");
            console.log(e);
          });
      }
    },
    bloquer() {
      adherentService
        .bloquer(this.adherent)
        .then(() => {
          this.dialog = false;
          this.$emit("updatedAdherents");
        })
        .catch((e) => {
          console.log("serveur KO");
          console.log(e);
        });
    },
    debloquer() {
      adherentService
        .debloquer(this.adherent)
        .then(() => {
          this.dialog = false;
          this.$emit("updatedAdherents");
        })
        .catch((e) => {
          console.log("serveur KO");
          console.log(e);
        });
    },
    renouveller() {
      this.renouv = true;
    },
    closeRenouveller() {
      this.renouv = false;
    },
    renouvellerConf() {
      adherentService
        .renouveller(this.adherent, this.renouvellement)
        .then(() => {
          this.renouv = false;
          this.dialog = false;
          this.$emit("updatedAdherents");
        })
        .catch((e) => {
          console.log("serveur KO");
          console.log(e);
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteConfirm() {
      this.dialogDelete = false;
      adherentService
        .delete(this.adherent.id)
        .then(() => {
          this.dialog = false;
          this.$emit("updatedAdherents");
        })
        .catch((e) => {
          console.log("serveur KO");
          console.log(e);
        });
    },
    create() {
      this.dialog = true;
      this.logoPath = "";
      this.adherent = { prenom: "", id: "", role: "", nom: "", mail: "" };
    },
    convertDate(adate){
      return adate ? adate.substring(0,10) : "";  
        },
    open(adhId) {
      adherentService
        .get(adhId)
        .then((resutlt) => {
          this.adherent = resutlt.data;
          this.adherent.finLicenceForInput = this.convertDate(this.adherent.finLicenceForInput);

          this.logo = null;
          this.logoPath = "";
          this.logoPath = adherentService.getLogoPath(this.adherent);
        })
        .catch((e) => {
          console.log("serveur KO");
          console.log(e);
        });
      this.dialog = true;
    },
  },
};
</script>

<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
</style>
