import apiService from './apiService.js'


export default {    
    async getAll() {
        return   apiService.get('DictionnairesAvances/');
    },
      
    async get(dicoId) {
        return   apiService.get('DictionnairesAvances/'+encodeURI(dicoId));
    },

    async save(dico) {
        return   apiService.post('DictionnairesAvances/', dico);
    },

    async delete(dicoId) {
        return   apiService.delete('DictionnairesAvances/'+encodeURI(dicoId));
    },
    async search(   outil, item,nombre )
    {
      var request ={ ClientId:"x",
          RdvId:"x",
           Outil:outil,
            Item:item,
          Nombre:nombre
      };
          return   apiService.post('DictionnairesAvances/Search/', request);
      },
    }