import apiService from './apiService.js'


export default {    
    async getAll() {
        return   apiService.get('dictionnaires/');
    },
      
    async get(dicoId) {
        return   apiService.get('dictionnaires/'+encodeURI(dicoId));
    },

    async save(dico) {
        return   apiService.post('dictionnaires/', dico);
    },

    async delete(dicoId) {
        return   apiService.delete('dictionnaires/'+encodeURI(dicoId));
    },

    async getItem(outil, item) {
        var search = {Outil:outil, Item:item};
        return   apiService.post('dictionnaires/', search);
    },
    async search(   outil, item,nombre )
    {
      var request ={ ClientId:"x",
          RdvId:"x",
           Outil:outil,
            Item:item,
          Nombre:nombre
      };
          return   apiService.post('dictionnaires/Search/', request);
      },

    }